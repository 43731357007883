<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Create New Folder
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <form
        :class="{ 'error' : form.errors.get('form'), 'error' : formFormHasErrors }"
        @submit.prevent="submit"
      >
        <div class="">
          <label
            for="folderName"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Folder Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="layerName"
              ref="nameinput"
              v-model="form.name"
              type="text"
              placeholder=""
              autofocus
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            >
          </div>
        </div>
        <div class="mt-6 btn-group flex items-center justify-end">
          <template v-if="form.busy">
            <button
              class="btn"
              type="button"
            >
              <fa-icon
                icon="spinner"
                spin
                class="mr-1"
              /> Working
            </button>
          </template>
          <template v-else>
            <button
              class="btn"
              type="button"
              @click="$emit('close')"
            >
              Cancel
            </button>
            <button
              class="btn primary"
              type="button"
              @click.prevent="submit"
            >
              Create
            </button>
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {

  props: ['parent', 'project', 'navigate'],

  data () {
    return {
      form: new SparkForm({
        name: null
      }),
      formFormHasErrors: false
    }
  },

  computed: {

    readyToSubmit () {
      return this.form.name !== ''
    }
  },

  mounted () {
    this.$refs.nameinput.focus()
  },

  methods: {

    submit () {
      if (this.readyToSubmit && !this.form.busy) {
        this.formFormHasErrors = false
        this.form.startProcessing()
        this.$store.dispatch('assets/newFolder', {
          project: this.project,
          parent: this.parent,
          name: this.form.name
        })
          .then(result => {
            this.form.finishProcessing()
            this.$emit('close')
            alert.success('Folder created')

            if (this.navigate === true) {
              this.$router.push({
                name: 'content_folder',
                params: {
                  uuid: result.id,
                  slug: this.project.slug
                }
              })
            }
          })
          .catch(response => {
            alert.error('Failed to create folder')
            this.formFormHasErrors = true
            this.form.setErrors(response.data)
            this.form.finishProcessing()
          })
      }
    }

  }
}
</script>
